import * as React from 'react';
import illustration from '../../../img/illustration.png'

export default function RightInformation() {
    return (
        <>
           <img  style={{ maxWidth: "110%" }} src={illustration}/>
        </>
    );
}
